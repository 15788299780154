import noteapp from "../assets/svg/projects/project_1.png";
import musicapp from "../assets/svg/projects/project_2.png";
import resume from "../assets/svg/projects/project_4.png";
import sip from "../assets/svg/projects/project_3.png";
import hisabsewa from "../assets/svg/projects/mobile_view.png";
export const projectsData = [
  {
    id: 1,
    projectName: "Hisab Sewa",
    projectDesc:
      "Your all-in-one solution for financial tools, stock calculators, unit converters, and more, designed to simplify your daily calculations with ease and accuracy.",
    tags: ["Next JS"],
    code: "https://www.hisabsewa.com",
    demo: "https://www.hisabsewa.com",
    image: hisabsewa,
  },
  {
    id: 2,
    projectName: "Music Web App",
    projectDesc: "This is simple music app based on reactjs ",
    tags: ["React", "Bootsrap", "Material Ui"],
    code: "https://github.com/ashokbasnetofficial/music-app",
    demo: "https://reactjsmusicapp.netlify.app/",
    image: musicapp,
  },
  {
    id: 3,
    projectName: "Resume UI Clone",
    projectDesc: "This is static resume with HTML and advance CSS3",
    tags: ["HTML", "CSS"],
    code: "https://github.com/ashokbasnetofficial/resume",
    demo: "https://ashokbasnetofficial.github.io/resume/",
    image: resume,
  },
  {
    id: 4,
    projectName: "SIP Calculator",
    projectDesc:
      "A JavaScript-based SIP calculator to estimate returns on systematic investment plans accurately and efficiently.",
    tags: ["JavaScript", "CSS", "HTML"],
    code: "https://github.com/ashokbasnetofficial/sip-calculator",
    demo: "https://sip-calculator-js.netlify.app/",
    image: sip,
  },
  {
    id: 5,
    projectName: "Note App",
    projectDesc:
      "This project is simple note app which allow user to add,delete,edit note with category",
    tags: ["React", "Material UI", "React Bootstrap"],
    code: "https://github.com/ashokbasnetofficial/note-app",
    demo: "https://reactjs-note-app.netlify.app/",
    image: noteapp,
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
